
/* print styles */
@media print {

    body {
      margin: 0;
      color: #000;
      background-color: #fff;
      height: 100%;
        width: 100%;
    }

.content-wrap {
    margin-top: -32px;
    margin-bottom: 30px;
    padding: 0px;
    margin-left: 34px;
    width: calc(100% - 58px);
    // border:2px solid #ff0000;
}
.table-striped{
    width: 100%;
}
.alink:hover{text-decoration: underline; word-break: break-all;}
.mat-table {
    background: #fff;
    width: 100%;
    height: 100%;
    // min-height: 320px !important;
}
.recipients-list{
 width: 80%;
}
.subject-details{width: 80% !important; }
.progress-bar{ width: 95% !important;}

.tr.mat-header-row {
    height: 40px !important;
}

.mat-row, .mat-header-row, .mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border-bottom-color: #b7b6b61f !important;
}
.mat-cell, .mat-footer-cell {
    color: #7d7d7dde !important;
}
.mat-table {
    background: #fff;
    // min-height: 320px !important;
}
.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
    color: #7d7d7dde !important;
}
.mat-header-cell {
    color: #1aa9f8 !important;
    background: #1266f10d;
    
}
.table-striped>tbody>tr>td, .table-striped>tbody>tr>th, .table-striped>tfoot>tr>td, .table-striped>tfoot>tr>th, .table-striped>thead>tr>td, .table-striped>thead>tr>th {
    border: 1px solid #ddd !important;
}
.table-striped>tbody>tr>td, .table-striped>tbody>tr>th, .table-striped>tfoot>tr>td, .table-striped>tfoot>tr>th, .table-striped>thead>tr>td, .table-striped>thead>tr>th {
    padding: 8px !important;
    line-height: 1.42857143 !important;
}


.inner-table>tbody>tr>td, .inner-table>tbody>tr>th, .inner-table>tfoot>tr>td, .inner-table>tfoot>tr>th, .inner-table>thead>tr>td, .inner-table>thead>tr>th {
    border: 1px solid #ddd !important;
}
.inner-table>tbody>tr>td, .inner-table>tbody>tr>th, .inner-table>tfoot>tr>td, .inner-table>tfoot>tr>th, .inner-table>thead>tr>td, .inner-table>thead>tr>th {
    padding: 8px !important;
    line-height: 1.42857143 !important;
}
.table-res{
    margin: 0px; max-width: 100%; overflow: auto; 
}
.table-h {
    min-height: 297px;
    border: 1px solid #ddd;
}

.page-container{
    box-shadow: 0 0px 2px rgb(0 0 0 / 50%);border: 1px solid #fff; width:100%; 
min-height:475px;background-color: #fff;padding: 15px; position: relative; border-radius: 6px;
}
.content-wrap .inner-header {
    margin: 0px 12px;
    text-align: right;
}
.inner-header .fa-download{display: none !important;}
.inner-header .fa-filter{display: none !important;}
.inner-header .fa-print{display: none !important;}
.inner-header .fa-sign-out{display: none !important;}
.inner-header .fa-user{display: none !important;}
.inner-header .dropdown{display: none !important;}
.page-container{
    box-shadow: 0 0px 0px rgb(0 0 0 / 50%);
    border: 0px solid #fff; 
    width:1024px; 
    // min-height:475px;
    background-color: #fff;
    padding: 0px; 
    position: relative; 
    border-radius: 6px;
}
.content-wrap {
    margin-top: 0px; 
    margin-bottom: 30px;
    padding: 0px; 
    margin-left: 34px;
    width: calc(100% - 58px);
}
.brand-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: #fff;
    height: 80px;
    width: 100%;
    padding: 0 20px;
    box-shadow: 0 0 0px rgb(0 0 0 / 50%);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
}
.footer {
    position: fixed !important;
}
/// progress bar print css ///

.circles{
    height: 2.538em;
    width: 2.538em;
    // border: 0.188em solid #000;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
  }
  
  .active{
      background-color:rgb(0, 128, 0);
  }
  .orangeActive{
    background-color:rgb(255, 165, 0);
  }
  
  .circle-desc-active{
  font-weight: bold;
  }
  .boldLine
  {
    height: 0.3em !important;
    color:rgb(0, 128, 0) !important;
    background-color: rgb(0, 128, 0) !important;
  }
  .orangeLine
  {
    height: 0.3em !important;
    color:rgb(255, 165, 0) !important;
    background-color: rgb(255, 165, 0) !important;
  }
  
  .bar{
    width: 40%;
    height: 0.063em;
    margin-bottom:0.969em;
    // border: 0.031em solid #000;
    color:#000;
    background-color:#000;
    display: inline-block;
    text-align:center;
    margin:"0 auto";
  }
  .invisiblebar{
    width: 30%;
    height: 0.063em;
    margin-bottom:0.469em;
   // border: 0.031em solid #000;
   // color:#000;
   // background-color:#000;
    display: inline-block;
    text-align:center;
    margin:0px auto;
    font-weight: 700;
    font-size: 18px;
  }

  .circle-desc{
   //position: absolute;
   margin-top: 4px;
   top:5.000em;
   font-size: 16px;
   width: auto;
   max-width:9.375em;
   margin-left:-0.750em;
  }

  .delivery-status {
    margin:0px;
    padding-top: 35px;
    padding: 0px;
}
  .status-details{
    margin-bottom: 50px;
      font-size: 20px;
        // background-color: #e5e5e5;
      display: inline;
      // padding: 5px 10px;
      display: block;
      width: 324px;
      border-radius: 5px;
  }
  .status-details1{
    margin-bottom: 15px;
      font-size: 20px;
      background: #1aa9f8;
      display: inline;
      padding: 5px 10px;
      display: inline-block;
      // width: 324px;
      border-radius: 5px;
      color:#fff;
  }
  .disp-none{ width: 70% !important;}
  div, img, table, table tr, table th, table tr td, table th, span, a, ul, ul li {-webkit-print-color-adjust: exact; }
  .btn-customize{display: none !important; 
  }
}
// print css end //