@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
.form-row1 {
  display: flex;
  justify-content: space-between;
}

.form-column1 {
  flex-basis: 48%;
  /* Adjust this value as needed */
}

@media screen and (max-width: 480px) {
  .inner-header h5 {
    margin-left: 10px !important;
  }

  .content-wrap.my-ms-1 {
    margin-left: -1px !important;
  }

  .mr-16 {
    margin-right: 0px !important;
  }

  /* for adjust the user profile icon in mobile responsive vertical*/
}
/*for Responsive in mobile devices*/
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-column {
    flex-basis: 100%;
  }

  .footer {
    position: inherit !important;
  }

  /*Issue fix for vertical*/
}
@media screen and (max-device-width: 767px) and (orientation: landscape) {
  .footer {
    position: inherit !important;
  }
}
/*For Galaxy fold in landscape*/
.forgotpassword {
  color: #1aa9f8;
  text-decoration: none;
  transition: all 0.3s;
  text-decoration: none;
  border: none;
  outline: none;
  background: none;
}

.forgotpassword:hover {
  text-decoration: underline;
}

.btn-login {
  background: #5a8eff;
  width: 100%;
  margin-top: 20px !important;
}

.disabled-button {
  background-color: grey;
  color: white;
  width: 300px;
  margin-top: 20px !important;
  padding: 0.5rem;
  border-radius: 4px !important;
  border: solid 1px transparent;
  font-size: 15px;
}

.text-left {
  text-align: left;
}

.text-green {
  color: #00C853;
}

.pt-30 {
  padding-top: 18px;
}

.pb-20 {
  padding-bottom: 20px;
}

.w-50 {
  min-width: 700px;
  max-width: 600px;
  margin: 0px auto;
}

.enabled-button {
  background-color: #5a8eff;
  color: white;
  width: 300px;
  margin-top: 20px !important;
  padding: 0.5rem;
  border-radius: 4px !important;
  border: solid 1px transparent;
}

.password-container {
  position: relative;
}

.password-container i {
  position: absolute;
  top: 32%;
  left: 10px;
}

p {
  margin-bottom: 10px;
  line-height: 0.5em;
  font-size: 17px;
}

/*login form*/
.login-form .register-form {
  max-width: 360px;
  margin-top: 0;
}

.login-form .alert {
  max-width: 360px;
  margin: 0 auto 8px;
  padding: 6px;
  border-radius: 0;
  text-align: center;
}

.login-form .form-control {
  max-width: 100%;
}

.login-form .btn {
  width: 100%;
}

/*Main style*/
/* Overide styling */
h3, label {
  color: #42474c;
}

button.nomargin {
  margin: 0;
}

.login-error.invisible {
  display: none;
  visibility: unset;
}

#page-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  display: block;
}

#page-loading-icontext {
  display: inline-block;
}

/* Style for loader */
#loading-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #e0e0e0;
  opacity: 0.4;
  display: none;
}

.processing-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  display: block;
}

#permalink {
  white-space: pre-wrap;
}

#footer .box-newsletter {
  width: 30%;
}

#footer .form {
  background: none;
  max-width: 485px;
  margin: 0 auto 15px;
  padding: 0;
  position: relative;
}

#footer .mc4wp-alert,
#footer .mc4wp-error,
label.error,
.select-error {
  background: none;
  border: 0;
  float: left;
  margin: 0;
  padding: 0;
  display: none;
  color: red;
}

.select-error {
  display: block;
}

/* Processing Modal*/
.modal {
  outline: none;
  position: absolute;
  margin-top: 0;
  top: 0;
  overflow: visible;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-processing {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  overflow: visible !important;
  z-index: 1035 !important;
}

#processingText {
  color: #428bca;
  font-size: 18px;
}

.modal-processing .modal-dialog,
.modal-processing .modal-content {
  width: 280px;
  height: auto;
  margin: 0 auto;
}

.modal-processing .modal-content {
  box-shadow: 0 0 3px 0px #000;
  background: #fff;
}

.modal-body {
  padding: 20px;
}

/*Features*/
.top-img {
  height: 140px;
}

.heading-more {
  color: #fff;
  font-size: 3rem;
  font-weight: normal;
  margin: 50px 0;
  text-align: center;
}

.wrap-table {
  width: 100%;
  position: relative;
}

.wrap-table > .row {
  width: 100%;
  display: table;
  position: relative;
}

#filter {
  text-align: center;
  padding: 90px 0;
}

.filters {
  position: relative;
}

.filters:after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  content: "";
  height: 0;
  margin: -8px 0 0;
  pointer-events: none;
  position: absolute;
  right: 25px;
  top: 50%;
  width: 0;
}

.dropdown a,
.dropdown a:visited,
.dropdown2 a,
.dropdown2 a:visited {
  color: #272f3b;
  text-decoration: none;
  outline: none;
}

.dropdown ul a:hover {
  color: #fff;
}

/*------------------------------------ New Css ------------------------------------*/
/* common css */
* {
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html,
body {
  -webkit-overflow-scrolling: touch;
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Lato", sans-serif;
  color: #42474c;
  font-size: 14px;
  background: #eff2f9;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  border: none;
  outline: none;
}

a {
  color: #1aa9f8;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover,
a:focus {
  color: #2f86d4;
}

p {
  margin-bottom: 0px;
  line-height: 1.7em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 300;
  line-height: 1.3em;
  color: #333;
  letter-spacing: -0.01em;
  margin: 0;
}

h1 {
  font-size: 3.857em;
  margin-bottom: 1.25em;
}

h2 {
  font-size: 2.3em;
  margin-bottom: 0.3em;
}

h3 {
  font-size: 1.929em;
  font-weight: 300;
  margin-bottom: 0.3em;
}

h4 {
  font-size: 1.5em;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 2em;
}

h5 {
  font-size: 1.143em;
  font-weight: 700;
  margin-bottom: 10px;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

label {
  display: block;
}

input,
select,
textarea,
button {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input,
select,
textarea {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  display: inline-block;
  border: solid #ccc 1px !important;
  margin: 0;
  padding: 15px 20px 14px;
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  width: 100%;
}

input:focus,
select:focus {
  border-color: #2cace3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 174, 239, 0.5);
}

input.valid,
select.valid {
  border-color: #2cace3;
}

input.invalid,
select.invalid {
  border-color: #ff4d07;
}

input.invalid:focus,
select.invalid:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 77, 7, 0.5);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

select {
  background-size: 25px;
  padding-right: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

button,
select,
a,
.cursor-pointer {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.btn {
  display: inline-block;
  margin: 0;
  padding: 8px 18px;
  border: solid 1px transparent;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: inherit;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s;
}

.btn:hover {
  color: #fff;
  text-decoration: none;
}

.btn-green {
  background: #3ab16d;
  color: #fff;
}

.btn-green:hover {
  background: #49d26d;
}

.btn-blue {
  background: #5a8eff;
}

.btn-blue:hover {
  background: #4873d0;
}

.btn-dark-blue {
  background: #272f3b;
}

.btn-dark-blue:hover {
  background: #3ab16d;
}

.btn-gray {
  background: #f3f3f3;
  color: #272f3b;
}

.btn-gray:hover {
  background: #3ab16d;
  color: #fff;
}

.btn-red {
  background-color: #c3272e;
  color: #fff;
}

.btn-red:hover {
  background-color: #f21513;
  color: #fff;
}

/* Basic styles */
input[type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.check-box label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 28px;
  line-height: 18px;
  cursor: pointer;
}

.check-box label::before,
.check-box label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  transition: 0.25s all ease;
}

.check-box label::before {
  content: " ";
  border: solid 1px #49d26d;
  border-radius: 2px;
}

/* Checkbox */
.check-box input[type=checkbox] + label::after {
  content: "✔";
  color: #fff;
  line-height: 18px;
  text-align: center;
}

/* :checked */
.check-box input[type=checkbox]:checked + label::before {
  background: #49d26d;
}

.check-box input[type=checkbox] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.check-box input[type=checkbox]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/*error*/
.has-error .form-control {
  border-color: #a94442;
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.mat-input-element .input {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  display: inline-block;
  border: solid #ccc 1px;
  margin: 0;
  padding: 15px 20px 14px;
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

input.mat-input-element {
  /* margin-top: -0.0625em; */
}

.mat-input-element, .mat-input-element::-webkit-search-cancel-button, .mat-input-element::-webkit-search-decoration, .mat-input-element::-webkit-search-results-button, .mat-input-element::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-control {
  height: auto;
  padding: 11px 32px !important;
  border-radius: 2px !important;
}

.for-pop-pw .form-control {
  padding: 11px 14px !important;
}

textarea.mat-input-element {
  margin: 0px 0px 1px -14px !important;
  width: 97% !important;
}

.has-error .control-label,
.has-error .help-block {
  color: #a94442;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.alert {
  padding: 0px 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100% !important;
  margin-top: 3px;
  font-weight: 400;
}

.top-triangle:before {
  content: "";
  position: absolute;
  right: 20px;
  left: auto;
  top: -10px;
  width: 15px;
  height: 15px;
  border-radius: 6px 0 0;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  overflow: hidden;
}

/*scrollbar*/
.scroll-bar::-webkit-scrollbar {
  height: 10px;
  background-color: #F5F5F5;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #555;
}

/*header*/
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  height: 80px;
  padding: 0px 20px;
  margin-bottom: 10px;
  border-top: 3px solid #c3272e;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.logo1 {
  margin: 0px auto;
  width: 100px;
  font-size: inherit;
  line-height: 0;
  vertical-align: text-bottom;
}

.logo {
  margin: 30px auto 10px;
  width: 100px;
  font-size: inherit;
  line-height: 0;
  vertical-align: text-bottom;
}

.content-wrap {
  margin-top: 60px;
  margin-bottom: 30px;
  padding: 20px;
  margin-left: 34px;
  width: calc(100% - 58px);
}

.float-right {
  float: right;
}

.table-striped {
  min-height: 110px;
  width: 100%;
  float: left;
}

.table-h {
  min-height: 297px;
  border: 1px solid #ddd;
}

.table-striped table tr td {
  font-size: 14px;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  min-width: 300px;
}

.no-header .logo-wrppper {
  text-align: center;
}

/*wrapper*/
.wrapper {
  max-width: 940px;
  margin: 0 auto;
}

.no-header {
  padding-top: 50px;
}

.no-header .logo {
  width: 130px;
}

/*form wrapper*/
.form-wrapper {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
}

.form-wrapper li {
  margin-bottom: 20px;
  line-height: 24px;
  font-weight: 700;
}

.form-wrapper li label {
  margin-bottom: 0px;
  font-weight: 700;
}

.reg-help {
  float: none;
  margin: 0;
  font-size: 11px;
}

/*register*/
.register-form {
  background: #fff;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 40px;
}

.Signin-form {
  max-width: 420px;
  min-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 9px 35px 0px 35px;
  background: #fbfdff;
  box-shadow: 0 25px 75px rgba(16, 30, 54, 0.25) !important;
  margin-top: 20px;
}

.login-form-min {
  min-height: 95vh;
}

.min-h {
  min-height: 97vh;
}

.popup {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 230px !important;
  background: #de1212;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: relative;
  z-index: 1;
  left: 50%;
  margin-left: -383px;
  left: 4.5rem;
  bottom: 33px;
  font-size: 11px !important;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #de1212 transparent transparent transparent;
}

/* show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 4s forwards;
  animation: fadeIn 4s forwards;
}

.rester-form.for-pop-pw {
  margin-top: 15px;
}

.Signin-form .disabled-button {
  background-color: #808080;
  color: white;
  margin-top: 0px !important;
  padding: 0.5rem;
  border-radius: 4px !important;
  border: solid 1px transparent;
  width: 100%;
  font-size: 16px;
}

.Signin-form small {
  font-weight: 400;
  line-height: 34px;
  color: #524f4f;
  font-size: 15px;
  padding-bottom: 10px;
}

.register-form small {
  font-weight: 400;
  line-height: 34px;
  color: #524f4f;
  font-size: 18px;
  padding-bottom: 10px;
}

li.register-here {
  margin-bottom: 0px;
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.or_options {
  margin-top: 0px !important;
}

.Signin-form .enabled-button {
  background-color: #007bff;
  color: white;
  margin-top: 0px !important;
  padding: 0.5rem;
  border-radius: 4px !important;
  border: solid 1px transparent;
  width: 100%;
  font-size: 16px;
}

.register-form {
  background: #fff;
  min-width: 700px;
  max-width: 700px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: left; */
  width: 100%;
  border-radius: 10px;
  padding: 15px 40px 25px;
  background: #fbfdff;
  box-shadow: 0 25px 75px rgba(16, 30, 54, 0.25) !important;
}

.ml-3 {
  margin-left: 3px;
}

.form-wrapper .register-form-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.register-form-btn .btn {
  width: 48%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form-btn .btn.btn-blue {
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 1em 0em 1em 0em;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*login form*/
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 125px;
  background-color: #e5e5e5;
  color: #000;
  text-align: center;
  padding: 5px 0;
  border-radius: 3px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  font-size: 8pt;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #ccc;
  color: #006080;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.img-socialmedia {
  max-width: 20px;
  height: 20px;
  margin: 0px;
}

.login-form .register-form {
  max-width: 360px;
  margin-top: 0;
}

.login-form .alert {
  max-width: 360px;
  margin: 0 auto 8px;
  padding: 6px;
  border-radius: 0;
  text-align: center;
}

.login-form .form-control {
  max-width: 100%;
}

.login-form .btn {
  width: 100%;
}

.form-wrapper .login-links label {
  font-weight: bold;
}

.form-wrapper li.login-links {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px;
}

.form-wrapper li.login-links .check-box {
  border-right: solid 1px #ccc;
  padding-right: 9px;
}

.register-here {
  margin: 10px 0px 0px 0px;
  text-align: center;
  font-weight: normal !important;
  color: #666;
}

/*user login dropdown*/
.profile-ava {
  display: inline-block;
  width: 60px;
  height: 63px;
  font-size: 26px;
  line-height: 60px;
  text-align: center;
  border-radius: 0;
  vertical-align: middle;
}

.user-menu a {
  display: block;
  color: #777;
  transition: all 0.3s;
}

.user-menu a:hover {
  color: #5a8eff;
  background: rgba(0, 0, 0, 0.05);
}

.open > .dropdown-menu {
  right: -2px;
  left: auto;
  width: auto;
  min-width: 230px;
  border-radius: 0;
  padding: 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

.open > .dropdown-menu li:first-child {
  padding: 18px;
  background: #5a8eff;
  color: #fff;
}

.open > .dropdown-menu li a {
  padding: 8px 18px;
}

.open > .dropdown-menu li a:hover {
  color: #2f86d4;
}

.open > .dropdown-menu.top-triangle:before {
  top: -8px;
  right: 22px;
  background: #5a8eff;
}

.user-menu.open .dropdown-menu li .fa {
  margin-right: 4px;
}

.inner-header .fa {
  font-size: 18px !important;
  border-radius: 0.25rem;
  text-transform: uppercase;
  padding: 13px 10px 12px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  margin: 1px;
}

.inner-header .fa:hover {
  color: #fff;
}

/*tracking*/
.message-id-list {
  background: #fff;
  padding: 10px;
  margin: 20px 0;
}

.message-id-list h4 {
  font-weight: bold;
}

.message-id-list table tr td {
  vertical-align: middle;
}

.message-id-list thead .info td {
  border-bottom: none;
}

/*message*/
.breadcrums {
  background: #1266f10d;
  padding: 0px 20px;
  line-height: 44px !important;
  float: left;
  width: 100%;
}

.breadcrums li:after {
  content: "";
  display: inline-block;
  margin-left: 10px;
  font-family: FontAwesome;
  font-size: 16px !important;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 44px;
  vertical-align: top;
}

.breadcrums li:last-child():after {
  content: none;
}

.breadcrums li {
  display: inline-block;
  padding-right: 10px;
  font-size: 13px;
  font-weight: bold;
}

.breadcrums li:last-child {
  padding-right: 0;
}

.message-details tr td {
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.message-details tr td label {
  display: inline-block;
  max-width: 150px;
  width: 100%;
  background-color: #1266f10d;
  padding: 8px 15px;
  margin-right: 10px;
}

.btn-right {
  float: right;
  margin-top: 4px !important;
}

.message-details tr td:last-child > div {
  display: inline-block;
}

.delivery-status {
  margin: 0px;
  padding-top: 35px;
  width: 50%;
  padding: 0px;
}

.mat-step-header .mat-step-icon {
  background-color: #d7d7d7;
  color: #acabab;
  border: 1px solid #cecece;
  z-index: 99;
}

.mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #18b714 !important;
  color: #fff !important;
  z-index: 99;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #18b714 !important;
  color: #fff !important;
  z-index: 99;
}

.message-details {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mat-tab-label {
  font-size: 18px !important;
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  padding: 10px 10px !important;
  line-height: 1;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px 5px 0px 0px;
  font-size: 14px !important;
  height: 36px !important;
  min-width: 125px !important;
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-label-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
  margin-top: 20px;
}

.mat-paginator-container {
  box-shadow: none;
  border-radius: 2px;
  border-bottom: none;
  width: 100%;
  min-height: 20px;
}

.mat-paginator-page-size {
  display: flex;
  align-items: baseline;
  margin-right: 8px;
  margin-bottom: -20px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.23em;
}

.mat-paginator-page-size-select {
  margin: -13px 0px 0 0px !important;
  width: 56px;
}

.mat-paginator-page-size-select {
  margin: -13px 4px 0 4px;
  width: 56px;
}

.delivery-status h4 {
  font-size: 16px;
}

.delivery-status li label,
.delivery-status li .status > div {
  display: inline-block;
}

.delivery-status li .status {
  margin: 12px 0;
}

.delivery-status li .status,
.delivery-status li .progress {
  display: block;
}

/*forgot password popup*/
.forgot-password .alert {
  margin: 0 0 10px;
  max-width: 100%;
}

.forgot-password h4 {
  margin-bottom: 20px;
}

.forgot-password .modal-dialog {
  max-width: 480px;
  width: 100%;
}

.forgot-password .modal-body {
  margin: 0 10px;
}

.forgot-password .btn-wrapper {
  width: 100%;
  text-align: center;
  margin: 20px 0 5px;
}

.forgot-password .btn-wrapper .btn {
  width: 46%;
  margin-right: 10px;
}

.forgot-password .btn-wrapper .btn:last-child {
  margin-right: 0;
}

#frmRegister .alert {
  max-width: 480px;
  margin: 30px auto 8px;
  padding: 6px;
  border-radius: 0;
  text-align: center;
}

/*help*/
.help-wrapper {
  max-width: 780px;
  background: #fff;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.5);
  line-height: 26px;
  text-align: center;
  font-weight: bold;
}

/*reset password*/
.reset-password-form .alert {
  max-width: 360px;
  margin: 0 auto 8px;
  padding: 6px;
  border-radius: 0;
  text-align: center;
}

.reset-password {
  background: #fff;
  max-width: 400px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.31);
  border-radius: 4px;
  min-width: 400px;
}

.pull-left {
  text-align: left;
}

.pw-is-d {
  padding: 30px 0 0 30px;
}

.pw-is-d h6 {
  font-size: 17px;
}

.pw-is-d ul li {
  text-align: left;
  line-height: 29px;
  font-size: 16px;
  font-family: Lato, sans-serif;
}

.pw-is-d ul {
  padding-left: 20px;
  margin-top: 20px;
}

.reset-password h4 {
  background: #272f3b;
  margin-bottom: 0;
  padding: 12px 8px 10px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.reset-password .form-wrapper {
  padding: 20px 30px 2px 30px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.reset-password .form-wrapper li label {
  margin-bottom: 5px;
  text-align: left;
}

.reset-password .form-wrapper li .btn {
  display: block;
  margin: 0 auto;
  max-width: 220px;
  font-size: 16px;
  line-height: 22px;
}

.checked {
  color: green;
}

.form-wrapper .password-hint {
  margin: 25px 0;
}

.password-hint h5 {
  text-transform: none;
}

.password-hint li {
  margin-bottom: 2px;
}

.password-error {
  color: #ff0000;
}

.password-hint li:before {
  content: "—";
  display: inline-block;
  margin: 2px auto 8px;
  background-color: transparent;
  border: 1px solid #ff0000;
  border-radius: 12px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #ff0000;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 19px;
}

.pgn {
  float: left;
  width: 100%;
  border: 1px solid #ddd;
}

.pgn .date-range {
  width: 45%;
  float: left;
  line-height: 18px;
  padding: 10px 22px;
  font-size: 12px;
  display: block;
  font-weight: 700;
  color: #7d7d7dde !important;
  display: inline;
}

.pgn .pagination-pannel {
  width: 55%;
  float: right;
  display: block;
  text-align: right;
}

/* mat-table css */
.d-none {
  display: none;
}

.table-container {
  overflow: auto;
}
.table-container table {
  width: 100%;
}
.table-container th, .table-container td {
  padding: 0 1rem;
}
.table-container th:nth-child(1) {
  width: 20px;
}
.table-container ::ng-deep .mat-form-field-wrapper {
  padding-bottom: 0;
}
.table-container .no-default-height {
  height: unset;
}
.table-container .filters-container {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
}
.table-container .filters-container.animate {
  max-height: 60px !important;
}

table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

.brand-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background: #fff;
  height: 80px;
  width: 100%;
  padding: 0 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.brand-header .header-co-logo {
  padding-top: 5px;
  max-width: 130px;
  max-height: 60px !important;
  justify-content: flex-end;
  justify-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}
.brand-header .header-co-logo img {
  margin-right: 0px;
  padding: 0;
  display: block;
  max-width: 100px;
  max-height: 60px;
}
.brand-header .header-text {
  width: 100%;
  font-size: 2em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
  text-align: center;
}
.brand-header .header-app-logo {
  margin: 0px;
  text-align: right;
  justify-items: flex-end;
  align-content: center;
  max-width: 130px;
  max-height: 60px !important;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}
.brand-header .header-app-logo img {
  margin: 0;
  padding: 0;
  display: flex;
  max-width: 100%;
  max-height: 50px !important;
}

/* mat-icon styles */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v99/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.mat-step-icon {
  width: 60px !important;
  height: 60px !important;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 54px;
  color: #e3e3e3 !important;
}

.mat-stepper-horizontal-line {
  border-top-width: 6px;
  color: #e3e3e3 !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 54px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  border-top-width: 6px;
  border-top-style: solid;
  content: "";
  display: inline-block;
  height: 0;
  position: absolute;
  width: calc(50% - 20px);
  color: #e3e3e3;
}

.mat-horizontal-stepper-header::before, .mat-horizontal-stepper-header::after, .mat-stepper-horizontal-line {
  background-color: green;
  border: solid;
}

.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 4px !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-tab-label-active {
  background: #5a8eff !important;
  color: #fff !important;
  opacity: 1 !important;
  border: 0px !important;
}

.mat-ink-bar {
  visibility: visible;
  left: 0px;
  width: 0px !important;
}

.mat-tab-label, .mat-tab-link {
  background-color: #eee;
  opacity: 1;
  cursor: not-allowed;
  border: 0px !important;
}

.table-striped table th {
  font-size: 14px;
  font-weight: 700;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
  height: 48px !important;
}

.footer {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  color: #777;
  height: 60px !important;
  font-family: lato, sans-serif;
  font-size: 12px;
  padding-top: 10px;
  width: 100%;
  border-top: 1px solid #ccc;
}

.footer p {
  font-family: Arial, Helvetica, sans-serif;
  display: inline;
}

.footer a {
  color: #c3272e;
}

.mat-row:nth-child(even) {
  background-color: #fff;
  min-height: 48px !important;
}

.mat-row:nth-child(odd) {
  background-color: #f9f9f9;
  height: 48px !important;
}

.mat-dialog-container {
  padding: 42px !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.42) !important;
}

.m-auto {
  margin: 0px auto;
  display: flex;
}

.btn-close {
  background-color: #c3272e;
  color: #fff;
  margin: 0px 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-code .form-control {
  padding: 10px 15px !important;
}

.btn-close-forgot {
  width: 20%;
  margin-left: 100px;
  background-color: #c3272e;
  color: #fff;
  margin: 0px 5px;
}

.btn-reset {
  background-color: #5a8eff;
  color: #fff;
}

.mat-dialog-title {
  font-weight: 700 !important;
}

.adv-src-wrapper {
  /* padding: 30px; */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.adv-src-form {
  background: #fff;
  max-width: 480px;
  /* margin: 30px auto 0; */
  /* box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 31%); */
  border-radius: 4px;
}

.adv-src-form li {
  margin-bottom: 3px;
  line-height: 35px;
  font-weight: 700;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0em 0 0em 0 !important;
}

.mat-form-field-infix input {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  display: inline-block;
  border: solid #ccc 1px;
  margin: 0;
  padding: 15px 20px 14px;
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.mat-form-field .mat-form-field .mat-select {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  display: inline-block;
  border: solid #ccc 1px;
  margin: 0;
  padding: 15px 20px 14px;
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  width: 100%;
  border: 1px solid #ff0000;
}

.mat-form-field-suffix button .mat-button-wrapper .mat-datepicker-toggle-default-icon {
  color: #007bff !important;
}

.mat-form-field .mat-datepicker-toggle {
  margin-top: -20px !important;
}

.mat-form-field-infix {
  border-top: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff;
}

.mat-form-field .mat-form-field-wrapper {
  border-bottom: none !important;
}

.footer {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  color: #777;
  min-height: 60px !important;
  font-family: lato, sans-serif;
  font-size: 11px;
  padding-top: 10px;
  width: 100%;
  border-top: 1px solid #ccc;
  float: left;
  display: inline-table;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  text-align: center;
}

.mh-80 {
  margin-bottom: 80px !important;
  margin-top: 15px;
}

.footer p {
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0px;
  margin: 0px;
}

.footer a {
  color: #c3272e;
}

.mat-select {
  display: inline-block;
  width: 100%;
  outline: none;
  height: 25px;
}

.recipients {
  line-height: 20px;
  display: inline-block;
  max-width: 150px;
  background: #1266f10d;
  padding: 8px 15px;
  margin-right: 10px;
}

.recipients-list {
  max-width: 100%;
  height: 52px !important;
  overflow-y: scroll;
  font-size: 12px;
  border: 1px solid #f8f8f8;
  padding: 5px;
  border-radius: 5px;
  float: left;
  overflow: auto;
  word-break: break-word;
}

.recipients-list .fa {
  background-color: none;
  line-height: 15px;
  padding: 2px;
  font-size: 10px !important;
}

.recipients-list .fa:hover {
  color: rgba(0, 0, 0, 0.6);
}

.recipients-list span {
  display: inline-block !important;
  line-height: 15px;
  margin-right: 10px !important;
  position: relative;
  border-radius: 2px;
}

.recipients-list span:hover {
  background-color: #1266f10d;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}

.subject {
  line-height: 20px;
  display: inline-block;
  max-width: 150px;
  background: #1266f10d;
  padding: 8px 15px;
  margin-right: 10px;
}

.subject-details {
  max-width: 100%;
  min-height: 37px !important;
  padding: 7px 0px;
  word-break: break-word !important;
}

.sender-s {
  line-height: 20px;
  word-break: break-all !important;
  max-width: 240px !important;
}

.subject-details span {
  display: inline-block !important;
  line-height: 20px;
  word-break: break-word !important;
}

.subject-p {
  max-width: 350px;
  word-break: break-all;
}

.subject-c {
  max-width: 350px;
  word-break: break-all;
}

.subject-grid table td:nth-child(1) {
  width: 5% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.subject-grid table th:nth-child(1) {
  width: 5% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.subject-grid table td {
  width: 15% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.subject-grid table th {
  width: 20% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.subject-grid table td:nth-child(5) {
  width: 33% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.subject-grid table th:nth-child(5) {
  width: 33% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.subject-grid table td:nth-child(6) {
  width: 20% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.subject-grid table th:nth-child(6) {
  width: 20% !important;
  word-break: break-word;
  padding: 0px 5px;
}

.mat-paginator-page-size-select {
  margin: 6px 9px 0 4px !important;
  width: 30px;
}

th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
  padding-right: 24px !important;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding: 0px 15px !important;
}

.inner-table table {
  border: 1px solid #ddd;
  margin: 24px 0px;
}

.inner-table table th:nth-child(1) {
  width: 30% !important;
  padding: 0px 5px;
}

.inner-table th:nth-child(1) {
  width: 30% !important;
  padding: 0px 5px;
}

.inner-table table th {
  width: 30% !important;
  padding: 0px 5px;
}

.inner-table th {
  width: 30% !important;
  padding: 0px 5px;
}

.pgn .mat-select {
  display: inline-block;
  width: 100%;
  outline: none;
  height: 25px;
  border-radius: 4px;
}

.pgn .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}

.pgn .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42) !important;
}

.pgn .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
}

.pgn .mat-paginator-page-size-select {
  width: 50px !important;
  text-align: center !important;
}

.mat-paginator-page-size-label {
  margin: 0px 23px !important;
  text-align: center !important;
}

.mat-select-panel .mat-primary {
  transform-origin: 50% 17.75px 0px;
  font-size: 12px;
  opacity: 1;
  min-width: calc(100% + 1px) !important;
  transform: scaleY(1);
}

.mat-option {
  padding: 0px 0px !important;
  text-align: center !important;
}

.mat-form-field-underline {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  margin-bottom: 0px !important;
}

.status-info {
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #fff !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 150px !important;
}

.SubmittedForSignature {
  background: #007bff !important;
}

.SignatureInProgress {
  background: #ffa500;
  border: #c58001;
}

.CancelledTransaction, .CompletedOnDecline {
  background: #c3272e !important;
}

.Completed {
  background: #00C853;
  border: #00C853;
}

.DeliveyFailed {
  background: #c3272e;
  border: #c3272e;
}

.tr.mat-header-row {
  height: 40px !important;
}

.mat-row, .mat-header-row, .mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: #b7b6b61f !important;
}

.mat-cell, .mat-footer-cell {
  color: #7d7d7dde !important;
}

.mat-cell {
  white-space: pre-line;
}

.mat-table {
  background: #fff;
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  color: #7d7d7dde !important;
}

.mat-header-cell {
  color: #1aa9f8 !important;
  background: #1266f10d;
}

.table-striped > tbody > tr > td, .table-striped > tbody > tr > th, .table-striped > tfoot > tr > td, .table-striped > tfoot > tr > th, .table-striped > thead > tr > td, .table-striped > thead > tr > th {
  border: 1px solid #ddd !important;
}

.table-striped > tbody > tr > td, .table-striped > tbody > tr > th, .table-striped > tfoot > tr > td, .table-striped > tfoot > tr > th, .table-striped > thead > tr > td, .table-striped > thead > tr > th {
  padding: 8px !important;
  line-height: 1.42857143 !important;
}

.inner-table > tbody > tr > td, .inner-table > tbody > tr > th, .inner-table > tfoot > tr > td, .inner-table > tfoot > tr > th, .inner-table > thead > tr > td, .inner-table > thead > tr > th {
  border: 1px solid #ddd !important;
}

.inner-table > tbody > tr > td, .inner-table > tbody > tr > th, .inner-table > tfoot > tr > td, .inner-table > tfoot > tr > th, .inner-table > thead > tr > td, .inner-table > thead > tr > th {
  padding: 8px !important;
  line-height: 1.42857143 !important;
}

.brand-header .header-text {
  display: none;
}

.table-res {
  margin-top: 0px;
  max-width: 100%;
  overflow: auto;
}

.page-container {
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  width: 100%;
  min-width: 400px;
  background-color: #fff;
  padding: 15px 30px;
  position: relative;
  border-radius: 6px;
  float: left;
  margin-bottom: 50px;
  min-height: 438px;
}

.tab-wrap {
  width: 100%;
}

.alink {
  text-decoration: none;
}

.alink:hover {
  text-decoration: underline;
}

.mat-header-cell {
  z-index: 98 !important;
}

i.fa.fa-info-circle.mt-1 {
  font-size: 11px;
  padding: 0.25rem;
  margin-top: 2.5px;
  color: #000000;
}

.open > .dropdown-menu.top-triangle:before {
  top: -8px;
  right: 12px;
  background: #5a8eff;
}

.top-triangle:before {
  content: "";
  position: absolute;
  right: 20px;
  left: auto;
  top: -10px;
  width: 15px;
  height: 15px;
  border-radius: 6px 0 0;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  overflow: hidden;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 88%;
  right: 0;
  z-index: 999;
}

.user-dropdown {
  padding: 0px 18px;
  background: #5a8eff;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
}

i.fa.fa-user.ml-6 {
  padding-left: 0px;
  color: #fff;
  padding-right: 16px;
}

i.fa.fa-sign-out.ml-7 {
  color: black;
  margin-left: 10px;
}

i.fa.fa-user.ml-7.btn-nav.dropdown-toggle {
  padding-left: 38px;
}

.signout-dropdown.ng-star-inserted {
  padding: 0px 0px;
  color: black;
  background: #fff !important;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.inner-header h5 {
  color: #fff;
  padding: 13px;
  margin-top: 0px;
  margin-left: 40px;
}

.inner-header h4 {
  color: #fff;
  padding: 13px;
  margin-top: 0px;
  margin-left: 43px;
}

.inner-header {
  background: #000;
  height: 50px;
  position: fixed;
  top: 79px;
  width: 100%;
  z-index: 999;
}

.inner-header ul li {
  list-style: none;
  display: inline-block;
}

.content-wrap.my-ms-1 {
  margin-top: 54px !important;
  margin-bottom: 30px;
  padding: 20px;
  margin-left: 34px;
  width: calc(100% - 58px);
}

.signout-ml-3 {
  margin-left: 2px;
}

.mr-16 {
  margin-right: 30px;
}

/*To adjust the icons to left*/
.content-wrap .inner-header {
  margin: 25px 12px;
  text-align: right;
}

.header-title {
  font-weight: bold;
  margin: 20px;
  display: inline;
  float: left;
}

.btn-nav {
  cursor: pointer;
}

.page-container .tabs-panel {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.fl {
  float: left;
}

.progress-bar-circles {
  width: 95%;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.pr-5 {
  padding-right: 5px;
}

.w-40p {
  width: 40%;
}

.w-20p {
  width: 20%;
}

.w-44p {
  width: 44%;
}

.w-56p {
  width: 56%;
}

.w-30p {
  width: 30%;
}

.h-60 {
  min-height: 45px;
}

.font-bold {
  font-weight: bold;
}

.p-0 {
  padding: 0px;
}

.fa-spin {
  font-size: 18px;
  color: #c3272e;
}

.text-center {
  text-align: center;
}

.mb-30 {
  margin-bottom: 30px;
}

.no-match-records {
  margin-top: 10%;
  margin-left: 40%;
}

.rp-border-grey {
  border: 1px solid #e5e5e5;
}

.m-10 {
  margin: 10px;
}

.status-bar1 {
  width: 100%;
  float: left;
  margin-top: -32px;
}

.no-records {
  float: left;
  width: 150px;
  text-align: center;
  margin-top: -150px;
  margin-left: 42%;
  display: inline;
}

.pswd-msg {
  margin: 10px;
  color: #181818;
}

.p-5 {
  padding: 31px;
}

.text-blue {
  color: #007bff;
}

.form-icon {
  margin: 0px auto;
  margin-bottom: 20px;
}

.form-icon img {
  max-width: 60px;
  min-width: 60px;
}

.text-green {
  color: #18b714;
}

.form-icon .fa {
  font-size: 50px;
  color: #18b714;
}

.pb-3 {
  padding-bottom: 2px;
}

.card-rs {
  background: #fff;
  min-width: 600px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.card-rs .card-header {
  background-color: #000;
  width: 100%;
  height: 50px;
}

.card-rs b {
  font-size: 17px;
}

.text-center {
  text-align: center;
}

.mat-snack-bar-container {
  color: #a94442;
  background-color: #f2dede;
}

.mat-snack-bar-container.red-snackbar {
  color: #a94442;
  background-color: #f2dede;
}

.mat-snack-bar-container.green-snackbar {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

/*mobile responsive*/
@media only screen and (max-width: 1024px) {
  .message-details tr td label :nth-child(2) {
    width: 30%;
    float: left;
  }

  .message-details tr td span :nth-child(2) {
    width: 60%;
    float: left;
    padding: 5px 0px;
  }

  .pgn .date-range {
    width: 35%;
  }

  .pgn .pagination-pannel {
    width: 65%;
  }

  .content-wrap {
    padding: 0px;
  }

  .content-wrap .inner-header {
    margin: 40px 12px 25px 12px;
  }
}
@media (max-width: 768px) {
  .pgn .date-range {
    width: 26%;
    padding: 10px 10px;
  }

  .pgn .pagination-pannel {
    width: 74%;
  }

  .mat-paginator-range-label {
    margin: 0 15px 0 15px !important;
  }

  .content-wrap {
    margin-left: 30px;
  }

  .page-container {
    padding: 15px;
  }

  .table-h {
    display: inline-block;
    width: 100%;
  }

  .w-56p {
    width: 100%;
  }

  .w-44p {
    width: 100%;
  }

  .status-bar1 {
    margin-top: -74px;
    font-weight: bold;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .forgot-password .modal-dialog {
    max-width: 100%;
    width: auto;
  }

  .content-wrap {
    margin-top: -10px !important;
    margin-bottom: 30px;
    padding: 0px !important;
    margin-left: 34px;
    width: calc(100% - 58px);
  }

  .page-container {
    padding: 15px 10px !important;
  }

  .inner-header .fa {
    padding: 12px 18px 12px;
  }
}
@media (max-width: 600px) {
  .no-records {
    margin-left: 35%;
  }
}
@media (max-width: 500px) {
  .inner-header .fa {
    padding: 12px 12px 12px;
  }
}
@media (max-width: 480px) {
  .no-header {
    padding: 30px 20px;
  }

  .forgot-password .modal-body {
    margin: 0;
  }

  .login-form.no-header {
    padding: 20px;
  }

  .form-wrapper {
    padding: 20px;
  }

  .form-wrapper li.login-links .check-box {
    padding-right: 18px;
  }

  .message-details tr td {
    margin-bottom: 12px;
    padding-bottom: 10px;
  }

  .message-details tr td label {
    display: block;
    max-width: 100%;
    margin-bottom: 10px;
    float: left;
  }

  .forgot-password.modalBanner {
    margin-top: 50px;
  }

  .content-wrap {
    margin-left: 8px;
    width: calc(100% - 20px);
  }

  .inner-header .fa {
    padding: 12px 12px 12px;
  }

  .header-title {
    margin: 10px;
  }

  .brand-header .header-co-logo img {
    max-width: 60px;
  }

  .w-56p {
    width: 100%;
  }

  .w-44p {
    width: 100%;
  }

  .status-bar1 {
    margin-top: -74px;
    font-weight: bold;
  }

  .brand-header .header-text {
    font-size: 1.4em;
  }

  .table-striped thead th {
    font-size: 13px;
  }

  .mat-dialog-content {
    display: block;
    margin: 0px -40px;
    padding: 0 16px;
    max-height: 65vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mat-dialog-content {
    display: block;
    margin: 0 -28px;
    padding: 0 4px;
    max-height: 65vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mat-dialog-container {
    padding: 24px 18px !important;
  }
}
@media only screen and (max-width: 390px) and (min-width: 377px) {
  .inner-header .fa {
    padding: 12px 10px 12px !important;
  }
}
@media (max-width: 376px) {
  .inner-header .fa {
    padding: 12px 7px 12px;
  }

  .footer {
    padding-bottom: 15px;
    width: 108%;
  }

  .content-wrap {
    width: calc(100% - -18px);
  }

  .cdk-overlay-pane {
    max-width: 92vw !important;
    border: 1px solid #ff0000;
  }
}
@media (max-width: 360px) {
  .inner-header .fa {
    padding: 8px 8px 12px;
  }

  .content-wrap .inner-header {
    margin: 20px 0px 15px 0px;
  }

  .page-container {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.5);
    border: 0px solid #fff;
  }

  .form-wrapper li.login-links {
    display: block;
    text-align: center;
  }

  .form-wrapper li.login-links .check-box {
    border-right: none;
    margin-bottom: 10px;
  }

  .breadcrums {
    padding: 13px 12px;
  }

  .breadcrums li {
    padding-right: 20px;
  }

  .message-details tr td span {
    word-break: break-all;
  }

  .forgot-password .btn-wrapper .btn {
    width: 46%;
  }
}
@media (max-width: 320px) {
  .mat-dialog-content {
    display: block;
    margin: 0 -24px;
    padding: 0 4px;
    max-height: 65vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mat-dialog-container {
    padding: 24px 12px !important;
  }

  .content-wrap {
    width: calc(100% - -75px);
  }

  .footer {
    width: 127%;
  }
}
@media screen and (min-width: 320px) and (max-width: 991px) and (orientation: landscape) {
  .mat-dialog-actions {
    padding: 10px !important;
    min-height: 20px !important;
    margin-top: -20px;
  }

  .mat-dialog-container {
    padding: 22px !important;
    margin: 10px;
    background: white;
    width: 100%;
    margin: 10px -20px 0px -2px;
  }
}